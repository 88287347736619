import React from 'react'
import { Link } from 'react-router-dom'

function Event() {
  return (
    <>
        <section className="event-section">
            <div className="container">
                    <div className="event-section-inner">
                    <div className="row">
                        <div className="col-lg-6">
                        <div className="section-title-box">
                            <div className="section-tagline">LATEST EVENTS</div>
                            <h2 className="section-title">
                            Upcoming Events
                            </h2>
                        </div>
                        
                        </div>
                       
                        <div className="col-lg-6">
                        <div className="event-content-box">
                            <div className="section-text">
                            <p>
                            The Inspector General of Police, Hon. Gregory O. W. Coleman 
                            has endorsed a secretariat to coordinate Gender issues in the security sector. 
                            </p>
                            </div>
                            
                        </div>
                       
                        </div>
                        
                    </div>
                    
                    <div className="row row-gutter-y-40">
                        <div className="col-xl-5">
                        <div className="event-subscribe-card">
                            <div className="event-details-card-title">
                            <div className="event-icon">
                                <i className="flaticon-envelope" />
                            </div>
                            
                            <h5>Subscribe</h5>
                            <p>Get latest news &amp; events details</p>
                            </div>
                            
                            <div className="event-details-card-content">
                            <form action="assets/inc/sendemail.php" className="contact-form" method="post" >
                                <div className="form-group">
                                <input type="email" id="Email" className="input-text" placeholder="Email address" name="email" required="" />
                                </div>
                               
                                <button className="btn btn-primary w-100">Subscribe</button>
                                
                                <p>Never share your email with others.</p>
                            </form>
                            
                            </div>
                            
                        </div>
                       
                        </div>
                       
                        <div className="col-xl-7">
                        <div className="event-card">
                            <div className="event-card-image">
                            <div className="event-card-image-inner">
                                <Link to="/#">
                                    <img src={"assets/images/event/event-3.png"} className="img-fluid" alt="img-20" />
                                </Link>
                                <div className="event-card-meta">
                                <div className="event-meta-number">
                                    <span>11</span>
                                </div>
                                
                                <div className="event-meta-date">
                                    <span>February 2025</span>
                                </div>
                                
                                </div>
                                
                            </div>
                          
                            </div>
                           
                            <div className="event-card-content">
                            <div className="event-card-info">
                                <ul className="list-unstyled">
                                <li>
                                    <i className="fa-solid fa-clock" />
                                    <span>1:00pm - 2:00pm</span>
                                </li>
                                
                                <li>
                                    <i className="fa-sharp fa-solid fa-location-pin" />
                                    <span>Police Headquarters, Monrovia</span>
                                </li>
                                
                                </ul>
                                
                            </div>
                           
                            <div className="event-card-title">
                                <h4>
                                <Link to="/#">
                                LNP Join Forces: A Special Drills To Mark Armed Froces Day 
                                </Link>
                                </h4>
                            </div>
                            
                            </div>
                            
                        </div>
                        
                        <div className="event-card">
                            <div className="event-card-image">
                            <div className="event-card-image-inner">
                                <Link to="/#">
                                <img src={"assets/images/event/event-2.png"} className="img-fluid" alt="img-21" />
                                </Link>
                                <div className="event-card-meta">
                                <div className="event-meta-number">
                                    <span>22</span>
                                </div>
                                
                                <div className="event-meta-date">
                                    <span>November 2024</span>
                                </div>
                                
                                </div>
                                
                            </div>
                          
                            </div>
                            
                            <div className="event-card-content">
                            <div className="event-card-info">
                                <ul className="list-unstyled">
                                <li>
                                    <i className="fa-solid fa-clock" />
                                    <span>11:00am - 2:00pm</span>
                                </li>
                                
                                <li>
                                    <i className="fa-sharp fa-solid fa-location-pin" />
                                    <span>Police Headquarters, Monrovia</span>
                                </li>
                                
                                </ul>
                                
                            </div>
                           
                            <div className="event-card-title">
                                <h4>
                                <Link to="/#">
                                LNP Deploys Additional Officers to South Sudan...
                                </Link>
                                </h4>
                            </div>
                            
                            </div>
                           
                        </div>
                        
                        </div>
                       
                    </div>
                    
                    </div>
                    
            </div>
        </section>

        <section className="cta-five-section">
            <div className="container">
                <div className="cta-five-card">
                <div className="cta-five-card-icon">
                    <i className="flaticon-file" />
                </div>
                
                <div className="cta-five-content">
                    <h4>Download Recent Events</h4>
                    <p>
                    The IG reaffirmed that the LNP plan is to prioritize safety for all road users. Motorcyclists are the most vulnerable 
                    in traffic, and as such, the LNP saw it necessary to put in some..
                    </p>
                </div>
                
                <div className="cta-five-button">
                    <Link to="#" className="btn btn-primary">
                    Download Files
                    </Link>
                </div>
                
                <div className="cta-five-img">
                    <i className="flaticon-file" />
                </div>
               
                </div>
                
            </div>
        </section>
    </>
  )
}

export default Event