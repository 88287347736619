import React from 'react'
import { Link } from 'react-router-dom'
import Blogdeatils01 from '../../../assets/image/blog/blog-details-1.png'
import Blogdeatils02 from '../../../assets/image/blog/blog-details-2.png'
import Blogdeatils03 from '../../../assets/image/blog/blog-details-3.png'
import Blogdeatils04 from '../../../assets/image/blog/blog-details-4.png'
import Blogdeatils011 from '../../../assets/image/blog/blog-details-11.png'
import Blogdeatils012 from '../../../assets/image/blog/blog-details-12.png'
import Blogdeatils013 from '../../../assets/image/blog/blog-details-13.png'

function First() {
  return (
    <>
        <section className="news-details-section">
            <div className="container">
                <div className="row">
                <div className="col-lg-8">
                    <div className="news-details-box-image">
                    <div className="news-details-box-image-inner">
                        <img src={Blogdeatils01} className="img-fluid" alt="img-193" />
                        <Link to="/newsdetails" className="news-details-box-date"> 22 NOV </Link>
                    </div>
                    
                    </div>
                   
                    <div className="news-details-meta-box">
                    <div className="news-details-meta-box-inner">
                        <span className="author"> by<Link to="/newsdetails">Admin</Link> </span>
                       
                        {/* <span className="comment">
                            <Link to="/newsdetails">02 Comments</Link>
                        </span> */}
                       
                    </div>
                    
                    </div>
                    
                    <div className="news-details-content-box">
                    <h4>LNP Deploys Additional Officers to South Sudan on UN Peacekeeping Mission</h4>
                    <p>
                    The Liberia National Police (LNP) has deployed eleven additional officers to South Sudan 
                    for peacekeeping mission. The farewell ceremony was held on Friday November 22, 2024, at 
                    the Headquarters of the Liberia National Police in Monrovia. The officers include; 
                    Commissioner of Police Ambrues N. Nebo, Deputy Commissioner of police Weah B. Goll-Nyensway, 
                    Assistant Commissioner of police Nicodemus S. Boye, Chief Superintendent of police Mayanley A. Kromah, 
                    Superintendents of police Mulbah Z. Jallah, Rosemarie B. Sepoh, Puchu Tarr, Veronica K. Barlingar, 
                    Oretha M. Paye, Chief Inspectors of police Bangaly A. Saysay and Samuel T. Varney. 
                    {" "}
                    </p>
                    <p>
                    Addressing the officers at the ceremony, Inspector General of Police Gregory O. W. Coleman 
                    emphasized the significance of the Peacekeeping Mission, charging them to uphold the ethics, 
                    values and principles of the LNP and be more community policing-oriented. IGP Coleman also encouraged 
                    the officers to render due diligence in the discharge of their respective duties as their actions 
                    represent the image of Law enforcement of Liberia in South Sudan The latest deployment brings to 
                    Twenty One the number of LNP Officers deployed in South Sudan on peacekeeping mission, signifying 
                    LNP's Commitment to global peace and stability
                    </p>
                    <p>
                        
                    </p>
                    </div>
                   
                    {/* <div className="news-details-share-box">
                    <div className="news-details-inner">
                        <div className="news-details-list">
                        <div className="news-details-list-title">
                            <h4>Tags</h4>
                        </div>
                       
                        <div className="news-details-list-button">
                            <Link to="/newsdetails" className="btn btn-primary"> Culture </Link>
                            
                            <Link to="/newsdetails" className="btn btn-primary"> Government </Link>
                        </div>
                        
                        </div>
                       
                        <div className="news-details-list">
                        <div className="news-details-socials">
                            <Link to="https://twitter.com/">
                                <i className="fa-brands fa-twitter" />
                            </Link>
                            <Link to="https://www.facebook.com/">
                                <i className="fa-brands fa-facebook" />
                            </Link>
                            <Link to="https://in.pinterest.com/">
                                <i className="fa-brands fa-pinterest-p" />
                            </Link>
                            <Link to="https://www.instagram.com/">
                                <i className="fa-brands fa-instagram" />
                            </Link>
                        </div>
                        
                        </div>
                        
                    </div>
                  
                    </div> */}
                    
                    {/* <div className="news-details-author-box">
                    <div className="news-details-author-image">
                        <img src={Blogdeatils05} alt="img-194" />
                    </div>
                   
                    <div className="news-details-author-content">
                        <h4>Jessica Brown</h4>
                        <p>
                        Cursus massa at urnaaculis estie. Sed aliquamellus vitae ultrs
                        condmentum lightly believable. If you are going to use a of you
                        need to be sure there isn't anything embarrassing.
                        </p>
                    </div>
                    
                    </div> */}
                    
                    {/* <div className="news-details-comment-list-box">
                    <h3>02 Comments</h3>
                    <div className="news-details-comment-image">
                        <img src={Blogdeatils06} alt={195} />
                        <div className="news-details-comment-content">
                        <div className="news-details-meta">
                            <div className="news-details-meta-number">
                            <h5>Kevin Martin</h5>
                            </div>
                           
                            <div className="news-details-meta-date">
                            <span>20 Oct, 2022</span>
                            </div>
                            
                        </div> */}
                       
                        {/* <div className="news-details-comment-text">
                            <p>
                            Cursus massa at urnaaculis estie. Sed aliquamellus vitae ultrs
                            condmentum lightly believable. If you are going to use a of
                            you need to be sure there isn't anything embarrassing.
                            </p>
                        </div> */}
                        
                        {/* </div> */}
                      
                        {/* <div className="news-details-comment-button">
                        <Link to="/newsdetails" className="btn btn-primary">
                            Reply
                        </Link>
                        </div> */}
                        
                    {/* </div> */}
                   
                    {/* <div className="news-details-comment-image">
                        <img src={Blogdeatils07} alt={196} />
                        <div className="news-details-comment-content">
                        <div className="news-details-meta">
                            <div className="news-details-meta-number">
                            <h5>Sarah Albert</h5>
                            </div>
                           
                            <div className="news-details-meta-date">
                            <span>20 Oct, 2022</span>
                            </div>
                            
                        </div>
                       
                        <div className="news-details-comment-text">
                            <p>
                            Cursus massa at urnaaculis estie. Sed aliquamellus vitae ultrs
                            condmentum lightly believable. If you are going to use a of
                            you need to be sure there isn't anything embarrassing.
                            </p>
                        </div>
                        
                        </div>
                      
                        <div className="news-details-comment-button">
                        <Link to="/newsdetails" className="btn btn-primary">
                            Reply
                        </Link>
                        </div>
                        
                    </div> */}
                   
                    {/* </div> */}
                   
                    <div className="news-details-comment-form">
                    <h3>Leave a Comment</h3>
                    <form className="contact-form contact-form-validated" action="assets/inc/sendemail.php" method="post" >
                        <div className="row row-gutter-10">
                        <div className="col-12 col-lg-6">
                            <input type="text" className="input-text" placeholder="Your name" name="name" aria-required="true" />
                        </div>
                       
                        <div className="col-12 col-lg-6">
                            <input type="email" className="input-text" placeholder="Email address" name="email" aria-required="true" />
                        </div>
                       
                        <div className="col-12 col-lg-12">
                            <textarea name="message" placeholder="Write a message" className="input-text " aria-required="true" defaultValue={""} />
                        </div>
                      
                        <div className="col-12 col-lg-12">
                            <button className="btn btn-primary">Submit Comment</button>
                        </div>
                      
                        </div>
                       
                    </form>
                   
                    </div>
                   
                </div>
               
                <div className="col-lg-4">
                    <div className="sidebar">
                    <div className="sidebar-form-content">
                        <div className="sidebar__item sidebar__item--search">
                        <form action="#" className="sidebar__search">
                            <label htmlFor="search" className="sr-only">
                            search here
                            </label>
                           
                            <input type="text" placeholder="Search Here" />
                            <button type="submit" aria-label="search submit" className="thm-btn" >
                            <i className="flaticon-search-interface-symbol" />
                            </button>
                          
                        </form>
                       
                        </div>
                        
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-recent-post">
                        <h4>Recent Posts</h4>
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils011} alt="img-197" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">LNP Initiates Rapid Drug Testing for PSU  </Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils012} alt="img-198" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">LNP Superintendent Earned an MBA</Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils013} alt="img-199" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">Crackdown on Police Corruption</Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-recent-category">
                        <div className="sidebar-widget-recent-category-box">
                        <h4 className="section-title text-left">Categories</h4>
                        <ul className="list-unstyled">
                            <li>
                            <Link to="/newsdetails">
                                City News
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Community
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Police <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Devlopement
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Government
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                        </ul>
                        
                        </div>
                        
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-tag">
                        <h4>Tags</h4>
                        <div className="sidebar-widget-tag-inner">
                        <Link to="/newsdetails">Police</Link>
                        <Link to="/newsdetails">Government</Link>
                        <Link to="/newsdetails">City</Link>
                        <Link to="/newsdetails">Development</Link>
                        <Link to="/newsdetails">Life</Link>
                        </div>
                        
                    </div>
                   
                    </div>
                   
                </div>
               
                </div>
               
            </div>
        </section>

         {/* Second News */}
        <section className="news-details-section">
            <div className="container">
                <div className="row">
                <div className="col-lg-8">
                    <div className="news-details-box-image">
                    <div className="news-details-box-image-inner">
                        <img src={Blogdeatils02} className="img-fluid" alt="img-193" />
                        <Link to="/newsdetails" className="news-details-box-date"> 23 DEC </Link>
                    </div>
                    
                    </div>
                   
                    <div className="news-details-meta-box">
                    <div className="news-details-meta-box-inner">
                        <span className="author"> by<Link to="/newsdetails">Admin</Link> </span>
                       
                        {/* <span className="comment">
                            <Link to="/newsdetails">02 Comments</Link>
                        </span> */}
                       
                    </div>
                    
                    </div>
                    
                    <div className="news-details-content-box">
                    <h4>LNP Initiates Rapid Drug Testing for PSU Officers</h4>
                    <p>
                    LNP Initiates Rapid Substance and Drug Testing for PSU Officers (Monrovia, Liberia – December 13, 2024) 
                    The Liberia National Police (LNP), in collaboration with the Madina Laboratory and Diagnostics Center, 
                    has initiated a rapid substance and drug testing program for over 100 Police Support Unit (PSU) 
                    officers at the LNP Headquarters. This initiative marks a significant step towards ensuring a drug-free 
                    police force and reinforcing public trust in the LNP...{" "}
                    </p>
                    <p>
                    According to the Administrator of Madina Laboratory 
                    and Diagnostics Center, Joe Daweah, the objective of the testing is not only to identify and address 
                    substance use within the LNP but also to provide necessary support to those in need. He stated that the 
                    testing will be followed by comprehensive follow-up support, including psychosocial assistance 
                    for officers who test positive.
                    </p>
                    <p>
                    Deputy Commissioner of Police Fitzgerald T. M. Biago, 
                    Chief of Human Resources of the LNP, emphasized the importance of the initiative: "The testing is a 
                    holistic approach aimed at supporting the well-being of our officers while maintaining the 
                    integrity and professionalism of the police force. A drug-free police force is crucial for the effective and trustworthy 
                    performance of the duties of the LNP." The initial phase of testing focuses on PSU officers, with plans to extend 
                    the program to patrol officers who have direct contact and engagement with the public. This expansion ensures 
                    that all officers maintaining public safety are held to the highest standards of conduct and responsibility. 
                    This initiative demonstrates the LNP's commitment to upholding the law and safeguarding the welfare of both its 
                    officers and the community. We believe that these efforts will significantly contribute to enhancing public 
                    confidence in the Liberia National Police.
                    </p>
                    </div>
                   
                    <div className="news-details-comment-form">
                    <h3>Leave a Comment</h3>
                    <form className="contact-form contact-form-validated" action="assets/inc/sendemail.php" method="post" >
                        <div className="row row-gutter-10">
                        <div className="col-12 col-lg-6">
                            <input type="text" className="input-text" placeholder="Your name" name="name" aria-required="true" />
                        </div>
                       
                        <div className="col-12 col-lg-6">
                            <input type="email" className="input-text" placeholder="Email address" name="email" aria-required="true" />
                        </div>
                       
                        <div className="col-12 col-lg-12">
                            <textarea name="message" placeholder="Write a message" className="input-text " aria-required="true" defaultValue={""} />
                        </div>
                      
                        <div className="col-12 col-lg-12">
                            <button className="btn btn-primary">Submit Comment</button>
                        </div>
                      
                        </div>
                       
                    </form>
                   
                    </div>
                   
                </div>

                <div className="col-lg-4">
                    <div className="sidebar">
                    <div className="sidebar-form-content">
                        <div className="sidebar__item sidebar__item--search">
                        {/* <form action="#" className="sidebar__search">
                            <label htmlFor="search" className="sr-only">
                            search here
                            </label>
                           
                            <input type="text" placeholder="Search Here" />
                            <button type="submit" aria-label="search submit" className="thm-btn" >
                            <i className="flaticon-search-interface-symbol" />
                            </button>
                          
                        </form> */}
                       
                        </div>
                        
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-recent-post">
                        <h4>Recent Posts</h4>
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils011} alt="img-197" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">LNP Initiates Rapid Drug Testing for PSU  </Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils012} alt="img-198" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">LNP Superintendent Earned an MBA</Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils013} alt="img-199" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">Crackdown on Police Corruption</Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-recent-category">
                        <div className="sidebar-widget-recent-category-box">
                        <h4 className="section-title text-left">Categories</h4>
                        <ul className="list-unstyled">
                            <li>
                            <Link to="/newsdetails">
                                City News
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Community
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Police <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Devlopement
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Government
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                        </ul>
                        
                        </div>
                        
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-tag">
                        <h4>Tags</h4>
                        <div className="sidebar-widget-tag-inner">
                        <Link to="/newsdetails">Police</Link>
                        <Link to="/newsdetails">Government</Link>
                        <Link to="/newsdetails">City</Link>
                        <Link to="/newsdetails">Development</Link>
                        <Link to="/newsdetails">Life</Link>
                        </div>
                        
                    </div>
                   
                    </div>
                   
                </div>
               
                </div>
               
            </div>
        </section>

         {/* Third News */}
        <section className="news-details-section">
            <div className="container">
                <div className="row">
                <div className="col-lg-8">
                    <div className="news-details-box-image">
                    <div className="news-details-box-image-inner">
                        <img src={Blogdeatils03} className="img-fluid" alt="img-193" />
                        <Link to="/newsdetails" className="news-details-box-date"> 16 JAN </Link>
                    </div>
                    
                    </div>
                   
                    <div className="news-details-meta-box">
                    <div className="news-details-meta-box-inner">
                        <span className="author"> by<Link to="/newsdetails">Admin</Link> </span>
                       
                        {/* <span className="comment">
                            <Link to="/newsdetails">02 Comments</Link>
                        </span> */}
                       
                    </div>
                    
                    </div>
                    
                    <div className="news-details-content-box">
                    <h4>LNP Superintendent Earned A Master's Degree</h4>
                    <p>
                    Superintendent Morris S. Langama, a dedicated officer of the Liberia National Police (LNP), 
                    has achieved significant milestones in his academic and professional journey. Langama’s 
                    commitment to excellence and continuous learning is evident through his impressive accomplishments, 
                    including earning a Master of Science in Environmental Development Studies & Natural Resource Management. 
                    Langama successfully completed his master’s degree at the University of Lay Adventists in Kigali, 
                    Republic of Rwanda. His focus areas included environmental studies, development, and natural resource management. 
                    Notably, he graduated with honors, demonstrating his dedication and expertise in these critical fields...{" "}
                    </p>
                    <p>
                    In addition to his master’s degree, Superintendent Langama obtained several certifications during his time in 
                    Rwanda: Mitigating, Preventing & Responding to Gender-Based Violence: Langama’s commitment to social 
                    development is evident through his certification in addressing Gender-based violence. This training equips him 
                    to contribute effectively to community safety and well-being. Grant & Proposal Writing: Langama’s proficiency in 
                    grant and proposal writing is a valuable asset for the LNP. This skill enables him to secure resources and 
                    funding for essential projects. Lecturer at the University of Lay Adventists: While pursuing his studies, 
                    Langama also served as a lecturer in the English and Environmental Science department. His dedication to education 
                    and knowledge-sharing is commendable. Langama’s educational journey extends beyond Rwanda: Today/Tomorrow’s 
                    Human Resource Professional (Certificate): Republic of Nigeria. Computer Science (Certificate): Future Focus, 
                    Republic of Rwanda Postgraduate Studies in Public Administration (Emphasis in English): Ghana Institute of 
                    Management & Public Administration. Seed of Peace (Certificate): United States Agency for International 
                    Development Bachelor of Science Degree (BSc) in Economics (Emphasis in English): University of Liberia. 
                    Superintendent Langama is a 2005 (Class – 1) Graduate of the National Police Training Academy and his 
                    contributions to law enforcement and community safety have been invaluable. Moreover, he has held various key positions 
                    within the LNP namely: Field Training Officer (FTO) Commander, Bentol Police Station Deputy Police Commander, 
                    Careysburg Police Detachment The recent educational achievement by Superintendent Morris S. Langama, 
                    coupled with his dedication to service and continuous improvement exemplify the LNP’s commitment to excellence. 
                    Inspector General of Police, Hon. Gregory O. W. Coleman, has lauded Superintendent Langama’s impressive academic 
                    accomplishments and encouraged him to apply the knowledge gained for the enhancement of the workings of the LNP.
                    </p>
                    <p>
                    IGP Coleman also emphasized the importance of other officers to follow Superintendent 
                    Langama’s brilliant example in order to contribute to the development of Liberia. Superintendent Langama’s academic achievement 
                    narrative would be incomplete without acknowledging the philanthropic efforts of Former Montserrado County District #1 Representative, 
                    Hon. Lawrence Morris. Hon. Morris, being passionate and deeply committed to human resource development and education, 
                    played a pivotal role in Langama’s educational journey by providing the opportunity for overseas travel in pursuit of his academic excellence.
                    We extend our heartfelt appreciation to Hon. Lawrence Morris and everyone who supported Superintendent Morris S. Langama in realizing his dream of earning a 
                    Master of Science in Environmental Development Studies & Natural Resource Management.
                    </p>
                    </div>
                   
                    <div className="news-details-comment-form">
                    <h3>Leave a Comment</h3>
                    <form className="contact-form contact-form-validated" action="assets/inc/sendemail.php" method="post" >
                        <div className="row row-gutter-10">
                        <div className="col-12 col-lg-6">
                            <input type="text" className="input-text" placeholder="Your name" name="name" aria-required="true" />
                        </div>
                       
                        <div className="col-12 col-lg-6">
                            <input type="email" className="input-text" placeholder="Email address" name="email" aria-required="true" />
                        </div>
                       
                        <div className="col-12 col-lg-12">
                            <textarea name="message" placeholder="Write a message" className="input-text " aria-required="true" defaultValue={""} />
                        </div>
                      
                        <div className="col-12 col-lg-12">
                            <button className="btn btn-primary">Submit Comment</button>
                        </div>
                      
                        </div>
                       
                    </form>
                   
                    </div>
                   
                </div>

                <div className="col-lg-4">
                    <div className="sidebar">
                    <div className="sidebar-form-content">
                        <div className="sidebar__item sidebar__item--search">
                        {/* <form action="#" className="sidebar__search">
                            <label htmlFor="search" className="sr-only">
                            search here
                            </label>
                           
                            <input type="text" placeholder="Search Here" />
                            <button type="submit" aria-label="search submit" className="thm-btn" >
                            <i className="flaticon-search-interface-symbol" />
                            </button>
                          
                        </form> */}
                       
                        </div>
                        
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-recent-post">
                        <h4>Recent Posts</h4>
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils011} alt="img-197" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">LNP Initiates Rapid Drug Testing for PSU  </Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils012} alt="img-198" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">LNP Superintendent Earned an MBA</Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils013} alt="img-199" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">Crackdown on Police Corruption</Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-recent-category">
                        <div className="sidebar-widget-recent-category-box">
                        <h4 className="section-title text-left">Categories</h4>
                        <ul className="list-unstyled">
                            <li>
                            <Link to="/newsdetails">
                                City News
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Community
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Police <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Devlopement
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Government
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                        </ul>
                        
                        </div>
                        
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-tag">
                        <h4>Tags</h4>
                        <div className="sidebar-widget-tag-inner">
                        <Link to="/newsdetails">Police</Link>
                        <Link to="/newsdetails">Government</Link>
                        <Link to="/newsdetails">City</Link>
                        <Link to="/newsdetails">Development</Link>
                        <Link to="/newsdetails">Life</Link>
                        </div>
                        
                    </div>
                   
                    </div>
                   
                </div>
               
                </div>
               
            </div>
        </section>

         {/* Fourth News */}
        <section className="news-details-section">
            <div className="container">
                <div className="row">
                <div className="col-lg-8">
                    <div className="news-details-box-image">
                    <div className="news-details-box-image-inner">
                        <img src={Blogdeatils04} className="img-fluid" alt="img-193" />
                        <Link to="/newsdetails" className="news-details-box-date"> 18 JAN </Link>
                    </div>
                    
                    </div>
                   
                    <div className="news-details-meta-box">
                    <div className="news-details-meta-box-inner">
                        <span className="author"> by<Link to="/newsdetails">Admin</Link> </span>
                       
                        {/* <span className="comment">
                            <Link to="/newsdetails">02 Comments</Link>
                        </span> */}
                       
                    </div>
                    
                    </div>
                    
                    <div className="news-details-content-box">
                    <h4>Crackdown on Police Corruption</h4>
                    <p>
                    This afternoon, while on my way from Nimba, I saw something really concerning. 
                    Some highway patrol officers were bothering drivers and taking bribes. 
                    This is a clear sign of some of the key issues we need to tackle to keep our standards high..{" "}
                    </p>
                    <p>
                    But what truly counts is our strong commitment to serving with honesty and integrity. 
                    These officers have been turned over to the Professional Standards Division for further disciplinary actions. 
                    We are committed to restoring public trust and ensuring that every officer serves with integrity, 
                    professionalism, and respect for the rule of law.
                    </p>
                    <p>
                        
                    </p>
                    </div>
                   
                    <div className="news-details-comment-form">
                    <h3>Leave a Comment</h3>
                    <form className="contact-form contact-form-validated" action="assets/inc/sendemail.php" method="post" >
                        <div className="row row-gutter-10">
                        <div className="col-12 col-lg-6">
                            <input type="text" className="input-text" placeholder="Your name" name="name" aria-required="true" />
                        </div>
                       
                        <div className="col-12 col-lg-6">
                            <input type="email" className="input-text" placeholder="Email address" name="email" aria-required="true" />
                        </div>
                       
                        <div className="col-12 col-lg-12">
                            <textarea name="message" placeholder="Write a message" className="input-text " aria-required="true" defaultValue={""} />
                        </div>
                      
                        <div className="col-12 col-lg-12">
                            <button className="btn btn-primary">Submit Comment</button>
                        </div>
                      
                        </div>
                       
                    </form>
                   
                    </div>
                   
                </div>

                <div className="col-lg-4">
                    <div className="sidebar">
                    <div className="sidebar-form-content">
                        <div className="sidebar__item sidebar__item--search">
                        {/* <form action="#" className="sidebar__search">
                            <label htmlFor="search" className="sr-only">
                            search here
                            </label>
                           
                            <input type="text" placeholder="Search Here" />
                            <button type="submit" aria-label="search submit" className="thm-btn" >
                            <i className="flaticon-search-interface-symbol" />
                            </button>
                          
                        </form> */}
                       
                        </div>
                        
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-recent-post">
                        <h4>Recent Posts</h4>
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils011} alt="img-197" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">LNP Initiates Rapid Drug Testing for PSU  </Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils012} alt="img-198" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">LNP Superintendent Earned an MBA</Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                        <div className="sidebar-recent-post">
                        <div className="sidebar-recent-post-img">
                            <img src={Blogdeatils013} alt="img-199" />
                        </div>
                       
                        <div className="sidebar-recent-post-content">
                            <div className="sidebar-meta">
                            <div className="sidebar-meta-item">
                                <div className="sidebar-meta-icon">
                                <span className="author">
                                    by<Link to="/newsdetails">Admin</Link>
                                </span>
                               
                                </div>
                               
                            </div>
                            
                            <div className="sidebar-post-title">
                                <h5>
                                <Link to="#">Crackdown on Police Corruption</Link>
                                </h5>
                            </div>
                          
                            </div>
                           
                        </div>
                       
                        </div>
                        
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-recent-category">
                        <div className="sidebar-widget-recent-category-box">
                        <h4 className="section-title text-left">Categories</h4>
                        <ul className="list-unstyled">
                            <li>
                            <Link to="/newsdetails">
                                City News
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Community
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Police <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Devlopement
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                            <li>
                            <Link to="/newsdetails">
                                Government
                                <i className="fa-solid fa-chevron-right" />
                            </Link>
                            </li>
                        </ul>
                        
                        </div>
                        
                    </div>
                    
                    <div className="sidebar-widget sidebar-widget-tag">
                        <h4>Tags</h4>
                        <div className="sidebar-widget-tag-inner">
                        <Link to="/newsdetails">Police</Link>
                        <Link to="/newsdetails">Government</Link>
                        <Link to="/newsdetails">City</Link>
                        <Link to="/newsdetails">Development</Link>
                        <Link to="/newsdetails">Life</Link>
                        </div>
                        
                    </div>
                   
                    </div>
                   
                </div>
               
                </div>
               
            </div>
        </section>
    </>
  )
}

export default First