import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import Logo from '../../assets/image/logo.png';

function Main() {

    const [Btnshow, setBtnshow] = useState(false);
    const [search, setsearch] = useState(false);
    const [Home, setHome] = useState(false);
    const [About, setAbout] = useState(false);
    const [Services, setServices] = useState(false);
    const [Media, setMedia] = useState(false);
    const [Organization, setOrganization] = useState(false);
    const [Resources, setResources] = useState(false);

  return (
    <>
        <header className="header">
            <div className="topbar">
                <div className="topbar-inner">
                <div className="topbar-left">
                    <div className="topbar-socials">
                    <a href="https://twitter.com/">
                        <i className="fa-brands fa-twitter" />
                    </a>
                    <a href="https://www.facebook.com/theLNP/">
                        <i className="fa-brands fa-facebook" />
                    </a>
                    <a href="https://in.pinterest.com/">
                        <i className="fa-brands fa-pinterest-p" />
                    </a>
                    <a href="https://www.instagram.com/">
                        <i className="fa-brands fa-instagram" />
                    </a>
                    </div>
                    
                    <div className="topbar-info">
                    <ul>
                        <li>
                        <div className="topbar-icon">
                            <i className="fa-solid fa-envelope" />
                        </div>
                        
                        <div className="topbar-text">
                            <Link to="mailto:info@lnp.gov.lr">info@lnp.gov.lr</Link>
                        </div>
                        
                        </li>
                        
                        <li>
                        <div className="topbar-icon">
                            <i className="fa-solid fa-clock" />
                        </div>
                        
                        <div className="topbar-text">
                            <span>Open Hours: 24/7</span>
                        </div>
                       
                        </li>
                        <li>
                        <div className="topbar-icon">
                            <i className="fa-solid fa-phone" />
                        </div>
                        
                        <div className="topbar-contact-item">
                         <Link to="tel:+231-770-122">0770 800-122</Link>
                        </div>
                       
                        </li>
                        <li>
                        <div className="topbar-icon">
                            <i className="fa-solid fa-phone" />
                        </div>
                        
                        <div className="topbar-contact-item">
                         <Link to="tel:+231-800-911">0770 800-911</Link>
                        </div>
                       
                        </li>
                        
                    </ul>
                    
                    </div>
                    
                </div>
                
                <div className="topbar-right">
                    <ul>
                    <li >
                        <Link to="/igcommand">Directorate</Link>
                    </li>
                    <li>
                        <Link to="/igcommand">Support Unit</Link>
                    </li>
                    <li>
                        <Link to="/reporting">Complaints</Link>
                    </li>
                    </ul>
                    
                </div>
                
                </div>
                
            </div>
            <div className="main-menu sticky-header">
                <div className="main-menu-inner">
                <div className="main-menu-left">
                    <div className="main-menu-logo">
                    <Link to="/">
                        {/* <img src={Logo} alt="img-1" width={140} /> */}
                        <img src={"assets/images/logo.png"} alt="img-1" width={140} />
                    </Link>
                    </div>
                   
                    <div className="navigation">
                    <ul className="main-menu-list list-unstyled">
                        
                        {/* <li className="active has-dropdown"> */}
                        <li>
                        <Link to="/">Home</Link>
                        {/* <ul className="list-unstyled">
                            <li>
                            <Link to="/">Home 1</Link>
                            </li>
                            <li>
                            <Link to="/hometwo">Home 2</Link>
                            </li>
                        </ul> */}
                        </li>
                        <li className="has-dropdown">
                        <Link to="#">About</Link>
                        <ul className="list-unstyled">
                            <li>
                            <Link to="/lnphistory">LNP History</Link>
                            </li>
                            {/* <li>
                            <Link to="/about">LNP History</Link>
                            </li> */}
                            <li>
                            <Link to="/missionandvision">Mission &amp; Vision</Link>
                            </li>
                            <li>
                            <Link to="/strategicplan">Strategic Plan 2020-2025</Link>
                            {/* <Link to="/portfoliodeatils">Strategic Plan 2020-2025</Link> */}
                            </li>
                            <li>
                            <Link to="/policeacademy">Police Academy</Link>
                            </li>
                            <li>
                            <Link to="/communityservice">Community Service</Link>
                            </li>
                            <li>
                            <Link to="/gender">Gender Unit</Link>
                            </li>
                            <li>
                            <Link to="/standards">Professional Standards</Link>
                            </li>
                            <li>
                            <Link to="/protectionsection">Women & Children Protection Section</Link>
                            </li>
                        </ul>
                        
                        </li>

                        <li className="has-dropdown">
                        <Link to="#">Organization</Link>
                        <ul className="list-unstyled">
                            <li>
                            <Link to="/igcommand">IGP Command</Link>
                            </li>
                            <li>
                            <Link to="/admincommand">Administration</Link>
                            </li>
                            <li>
                            <Link to="/opcommand">Operation Command</Link>
                            </li>
                            <li>
                            <Link to="/commandcsd">CSD Command</Link>
                            </li>
                            <li>
                            <Link to="/tcommand">Training Command</Link>
                            </li>
                        </ul>
                        
                        </li>
                        
                        <li className="has-dropdown">
                        <Link to="#">Services</Link>
                        <ul className="list-unstyled">
                            <li>
                            <Link to="/clearance">Police Clearance</Link>
                            </li>
                            <li>
                            <Link to="/reporting">Report a Crime</Link>
                            </li>
                            <li>
                            <Link to="/reporting">File a Complaint</Link>
                            </li>
                            {/* <li>
                            <Link to="/servicedetails">Community Relations</Link>
                            </li> */}
                            <li>
                            <Link to="/commend">Commend an Officer</Link>
                            </li>
                            <li>
                            <Link to="/reviewboard">Civilian Complaints &amp; Review Board</Link>
                            </li>
                        </ul>
                        
                        </li>
                        
                        <li className="has-dropdown">
                        <Link to="#">Information</Link>
                        <ul className="list-unstyled">
                            <li>
                            <Link to="/allresources">Police Act (2016)</Link>
                            </li>
                            <li>
                            <Link to="/allresources">Annual Reports</Link>
                            </li>
                            <li>
                            <Link to="/allresources">Freedom Of Information (FOI) Act</Link>
                            </li>
                            <li>
                            <Link to="/allresources">Revised Act to Ban Trafficking In Persons (2021)</Link>
                            </li>
                            
                            {/* <li>
                            <Link to="/internal">Internal Affairs</Link>
                            </li>
                            <li>
                            <Link to="/peacekeeping">Peacekeeping Missions</Link>
                            </li> */}
                            
                            <li>
                            <Link to="/questions">Frequently Asked Questions (FAQ)</Link>
                            </li>
                        </ul>
                        
                        </li>
                     
                        <li className="has-dropdown">
                        <Link to="#">Media</Link>
                        <ul className="list-unstyled">
                            <li>
                            <Link to="/newsdetails">News &amp; Events</Link>
                            </li>
                            <li>
                            <Link to="#">LNP TV</Link>
                            </li>
                            <li>
                            <Link to="#">Photo Gallery</Link>
                            </li>
                            <li>
                            <Link to="#">Videos</Link>
                            </li>
                            <li>
                            <Link to="#">Public Service Announcements</Link>
                            </li>
                        </ul>
                        
                        </li>
                        
                        <li>
                        <Link to="/contact">Contact</Link>
                        </li>
                        
                    </ul>
                   
                    </div>
                   
                </div>
                
                <div className="main-menu-right">
                    <div className="mobile-menu-button mobile-nav-toggler" onClick={() => { setBtnshow(true) }}> 
                        <span />
                        <span />
                        <span />
                    </div>
                   
                    <div className="search-box">
                        <Link to="#" className="search-toggler" onClick={() => { setsearch(true) }}>
                            <i className="flaticon-search-interface-symbol" />
                        </Link>
                    </div>
                    
                    <div className="main-menu-right-button">
                        <Link to="/reporting" className="btn btn-primary">
                            Report Crime
                        </Link>
                    </div>
                    
                </div>
                
                </div>
            </div>
        </header>

        {Btnshow && 
            <div className="mobile-nav-wrapper expanded">
                    <div className="mobile-nav-overlay mobile-nav-toggler" />
                    <div className="mobile-nav-content">
                        <a href="#" className="mobile-nav-close mobile-nav-toggler" onClick={() => setBtnshow(false)}>
                        <span />
                        <span />
                        </a>
                        
                        <div className="logo-box">
                        <Link to="/">
                            <img src="assets/images/logo-light.png" width={160} height={40} alt={"lNP"} />
                        </Link>
                        </div>
                        
                        <div className="mobile-nav-container">
                        <ul className="main-menu-list list-unstyled">
                            <li className="active has-dropdown">
                            <Link to="#">
                                Home
                                <button className={Home ? "expanded" : ""} onClick={() => { setHome(!Home) }}>
                                    <i className="fa-solid fa-chevron-right" />
                                </button>
                            </Link>

                            { Home && 
                                <ul className="list-unstyled" style={{display: 'block'}}>
                                    <li>
                                        <Link to="/">Mainhome</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="/hometwo">Home 2</Link>
                                    </li> */}
                                </ul>
                            }
                        
                            </li>
                            
                            <li className="has-dropdown">
                            <Link to="#">
                                About
                                <button className={About ? "expanded" : ""}>
                                    <i className="fa-solid fa-chevron-right"  onClick={() => { setAbout(!About) }} />
                                </button>
                            </Link>
                            {About && 
                                <ul className="list-unstyled" style={{display: 'block'}}>
                                    <li>
                                    <Link to="/lnphistory">LNP History</Link>
                                    </li>
                                    <li>
                                    <Link to="/missionandvision">Mission &amp; Vision</Link>
                                    </li>
                                    <li>
                                    <Link to="/strategicplan">Strategic Plan 2020-2025</Link>
                                    {/* <Link to="/portfoliodeatils">Strategic Plan 2020-2025</Link> */}
                                    </li>
                                    <li>
                                    <Link to="/policeacademy">Police Academy</Link>
                                    </li>
                                    <li>
                                    <Link to="/communityservice">Community Service</Link>
                                    </li>
                                    <li>
                                    <Link to="/gender">Gender Unit</Link>
                                    </li>
                                    <li>
                                    <Link to="/standards">Professional Standards</Link>
                                    </li>
                                    <li>
                                    <Link to="/protectionsection">Women & Children Protection Section</Link>
                                    </li>
                                    
                                </ul>
                            }
                        
                            </li>
                            
                            <li className="has-dropdown">
                            <Link to="#">
                                Organization
                                <button className={Organization ? "expanded" : ""}>
                                    <i className="fa-solid fa-chevron-right" onClick={() => { setOrganization(!Organization) }} />
                                </button>
                            </Link>

                            {Organization && 
                                <ul className="list-unstyled" style={{display: 'block'}}>
                                    <li>
                                    <Link to="/igcommand">IGP Command</Link>
                                    </li>
                                    <li>
                                    <Link to="/admincommand">Administration</Link>
                                    </li>
                                    <li>
                                    <Link to="/opcommand">Operation Command</Link>
                                    </li>
                                    <li>
                                    <Link to="/commandcsd">CSD Command</Link>
                                    </li>
                                    <li>
                                    <Link to="/tcommand">Training Command</Link>
                                    </li>
                                </ul>
                            }
                        
                            </li>

                            <li className="has-dropdown">
                            <Link to="#">
                                Services
                                <button className={Services ? "expanded" : ""}>
                                    <i className="fa-solid fa-chevron-right" onClick={() => { setServices(!Services) }} />
                                </button>
                            </Link>

                            {Services && 
                                <ul className="list-unstyled" style={{display: 'block'}}>
                                    <li>
                                        <Link to="/clearance">Police Clearance</Link>
                                        </li>
                                        <li>
                                        <Link to="/reporting">Report a Crime</Link>
                                        </li>
                                        <li>
                                        <Link to="/reporting">File a Complaint</Link>
                                        </li>
                                        {/* <li>
                                        <Link to="/servicedetails">Community Relations</Link>
                                        </li> */}
                                        <li>
                                        <Link to="/commend">Commend an Officer</Link>
                                        </li>
                                        <li>
                                        <Link to="/reviewboard">Civilian Complaints &amp; Review Board</Link>
                                    </li>
                                </ul>
                            }
                        
                            </li>
                            
                            <li className="has-dropdown">
                            <Link to="#">
                                Resources
                                <button className={Resources ? "expanded" : ""}>
                                    <i className="fa-solid fa-chevron-right"  onClick={() => { setResources(!Resources) }}  />
                                </button>
                            </Link>

                            {Resources && 
                                <ul className="list-unstyled" style={{display: 'block'}}>
                                    <li>
                                        <Link to="/allresources">Police Act (2016)</Link>
                                        </li>
                                        <li>
                                        <Link to="/allresources">Annual Reports</Link>
                                        </li>
                                        <li>
                                        <Link to="/allresources">Freedom Of Information (FOI) Act</Link>
                                        </li>
                                        <li>
                                        <Link to="/allresources">Revised Act to Ban Trafficking In Persons (2021)</Link>
                                        </li>
                                        
                                        {/* <li>
                                        <Link to="/internal">Internal Affairs</Link>
                                        </li>
                                        <li>
                                        <Link to="/peacekeeping">Peacekeeping Missions</Link>
                                        </li> */}
                                        
                                        <li>
                                        <Link to="/questions">Frequently Asked Questions (FAQ)</Link>
                                    </li>
                                </ul>
                            }
                        
                            </li>
                            
                            <li className="has-dropdown">
                            <Link to="#">
                                Media
                                <button className={Media ? "expanded" : ""}>
                                    <i className="fa-solid fa-chevron-right" onClick={() => { setMedia(!Media) }}  />
                                </button>
                            </Link>

                            {Media && 
                                <ul className="list-unstyled" style={{display: 'block'}}>
                                    <li>
                                        <Link to="#">News &amp; Events</Link>
                                        </li>
                                        <li>
                                        <Link to="#">LNP TV</Link>
                                        </li>
                                        <li>
                                        <Link to="#">Photo Gallery</Link>
                                        </li>
                                        <li>
                                        <Link to="#">Videos</Link>
                                        </li>
                                        <li>
                                        <Link to="#">Public Service Announcements</Link>
                                    </li>
                                </ul>
                            }
                        
                            </li>
                            
                            {/* <li className="has-dropdown">
                            <Link to="#">
                                News
                                <button className={News ? "expanded" : ""}>
                                    <i className="fa-solid fa-chevron-right" onClick={() => { setNews(!News) }}  />
                                </button>
                            </Link>
                            {News && 
                                <ul className="list-unstyled" style={{display: 'block'}}>
                                    <li>
                                         <Link to="/news">News</Link>
                                    </li>
                                    <li>
                                        <Link to="/newsdetails">News Details</Link>
                                    </li>
                                </ul>
                            }
                        
                            </li> */}
                            
                            <li>
                            <Link to="/contact">Contact</Link>
                            </li>
                            
                        </ul>
                        </div>
                        
                        <ul className="mobile-nav-contact list-unstyled">
                        <li>
                            <i className="fa-solid fa-phone" />
                            <a href="tel:+231 770 800-122">0770 800-122</a>
                        </li>
                        
                        <li>
                            <i className="fa-solid fa-envelope" />
                            <a href="mailto:info@lpn.go.lr">info@lpn.go.lr</a>
                        </li>
                        
                        <li>
                            <i className="fa-solid fa-map-marker-alt" />
                            Charles Alphonso Caine Building, <br /> Capitol Hill, Monrovia, Liberia
                        </li>
                        
                        </ul>
                        
                        <ul className="mobile-nav-social list-unstyled">
                        <li>
                            <a href="https://twitter.com/">
                                <i className="fa-brands fa-twitter" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/theLNP/">
                                <i className="fa-brands fa-facebook" />
                            </a>
                        </li>
                        <li>
                            <a href="https://in.pinterest.com/">
                                <i className="fa-brands fa-pinterest-p" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/">
                                <i className="fa-brands fa-instagram" />
                            </a>
                        </li>
                        </ul>
                        
                    </div>
            </div>  
        }

        {search && 
            <div className="search-popup active" onClick={() => { setsearch(false) }}>
            <div className="search-popup-overlay search-toggler" /> 
            <div className="search-popup-content">
                <form action="#">
                <label htmlFor="search" className="sr-only">
                    search here
                </label>
                
                <input type="text" id="search" placeholder="Search Here..." />
                <button type="submit" aria-label="search submit" className="search-btn">
                    <span>
                    <i className="flaticon-search-interface-symbol" />
                    </span>
                </button>
                
                </form>
            
            </div>
            </div>
        }

        <a href="#" className="scroll-to-top scroll-to-target" data-target="html">
            <i className="fa-solid fa-arrow-up" />
        </a>

    </>
  )
}

export default Main